@use '../../app/theme/style/variables';

.mpr-buttons-row {
  flex: 0 1 50px;
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  &.mpr-button {
    border-radius: 30px;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    height: 45px;
    letter-spacing: normal;
    padding: 0 16px;

    &:disabled {
      opacity: 50%;
    }

    &.mpr-green-button {
      border-color: variables.$mpr-branding-text-color;
      color: variables.$mpr-branding-text-color;
    }

    &.mpr-blue-button {
      border-color: map-get(variables.$md-mpr-primary, 900);
      color: map-get(variables.$md-mpr-primary, 900);
    }

    &.mpr-yellow-button {
      background-color: map-get(variables.$md-mpr-accent, 700);
      color: map-get(variables.$md-mpr-primary, 900);
    }

    &.mpr-cancel-button {
      margin-right: 10px;
    }

    &.mpr-green-border-button {
      border: 1px solid;
      border-color: map-get(variables.$md-mpr-primary, 900);
      color: map-get(variables.$md-mpr-primary, 900);
    }
  }
}

.mat-mdc-button {
  .mdc-button__label {
    display: inline-flex;
    align-items: center;
    img{
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }
}

.mpr-button-refresh.mat-mdc-button {
  .mdc-button__label {
    display: inline-flex;
    align-items: center;
  }

  .mpr-refresh-img img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
}