@use '../../app/theme/style/variables';
@import './mat-select';

.mpr-form {
  border: 2px solid variables.$mpr-divider-color;
  margin-bottom: 25px;

  &-title {
    color: map-get(variables.$md-mpr-primary, 900);
    display: flex;
    font-size: 28px;
    justify-content: center;
    text-align: center;
    padding: 50px;
    border-bottom: 2px solid variables.$mpr-divider-color;
  }

  &-block {
    border-bottom: 2px dashed variables.$mpr-divider-color;
    margin: 25px 50px 0;
    padding: 0 20px 20px;

    &-field {
      justify-content: flex-start;
      align-items: center;
      display: flow-root;
      margin-bottom: 15px;

      &-input {
        &.mpr-calender-input.ng-valid {
          color: rgba(0, 0, 0, 0.87);
        }
      }

      &-label {
        display: block;
        padding-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
      }

      &-note-text {
        color: #707070;
        display: block;
        font-size: 12px;
      }

      &-wordcount {
        float: right;
        display: block;
        font-size: 12px;
        padding-right: 10px;
      }

      &-textarea {
        &.mat-mdc-input-element {
          width: calc(100% - 12px); // 12px: 10 px for padding 2 px for border
          height: 80px;
          margin-bottom: 5px;
          resize: none;

          &.ng-invalid.ng-touched {
            border-color: variables.$mpr-error-font-color;
          }
        }
      }

      &-textarea::-webkit-input-placeholder {
        color: variables.$mpr-placeholder-color;
      }

      &-textarea:-moz-placeholder {
        /* Firefox 18- */
        color: variables.$mpr-placeholder-color;
      }

      &-textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: variables.$mpr-placeholder-color;
      }

      &-textarea:-ms-input-placeholder {
        color: variables.$mpr-placeholder-color;
      }

      &-textarea::placeholder {
        color: variables.$mpr-placeholder-color;
      }
    }
  }

  &-block:last-child {
    border-bottom: 0;
    padding-bottom: 25px;
  }

  .mat-form-field-appearance-outline {
   
    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-radius: 0px;
      border-color: #414b66;
    }
  }
}

.mpr-metadata-dataTags-chip-list {
  width: 100%;
}

.mpr-form-fields-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-items {
    flex: 1;
  }

  &.mpr-flex-alignitems-start {
    align-items: flex-start;
  }
}

.mat-mdc-form-field-error {
  font-size: 12px;
  color:variables.$mpr-error-font-color;
}

.mpr-error-color {
  color: variables.$mpr-error-font-color;
  font-weight: bold;
}

.mpr-form-field-width-280 {
  width: 280px;
}

.mat-mdc-form-field{
  letter-spacing: normal;
}
