@use '@angular/material' as mat;
@use '../../app/theme/style/variables';

.mpr-mat-select-field.mat-form-field-appearance-outline {

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mat-mdc-select-arrow-wrapper {
    background: url(/assets/images/drop-down.svg) no-repeat right #ddd;
    /*To change default icon with provided image*/
    background-position-x: 98%;
    /*Adjust according to width of dropdown*/
    background-color: transparent;
    background-position-y: 35%;

    .mat-mdc-select-arrow {
      opacity: 0;
      margin: 0 7px;
    }
  }

  &.ng-invalid.ng-touched {

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
      border-color: #f44336;
    }
  }

}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0px;
  border-color: #414b66;

}

div.mat-mdc-select-panel.mpr-select-option-panel {
  padding: 0;
}

.mpr-select-option-panel {
  .mpr-placeholder-option {
    color: variables.$mpr-font-color1;
  }
}

.mat-mdc-select-panel {
  border-radius: 0px !important;
}

.mpr-select-source-spinner {
  margin-left: 10px;

  .mat-mdc-progress-spinner {
    display: inline;
  }
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
  background: #046b5c;
}
